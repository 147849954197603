<template>
  <v-tooltip
    v-model="isActiveTooltip"
    location="bottom"
    scroll-strategy="close"
    :eager="false"
    :text="currentIcon.tooltip"
    open-on-click
  >
    <template #activator="{ props: tooltipProps }">
      <v-icon
        v-bind="tooltipProps"
        class="cursor-pointer"
        size="24"
        :aria-label="currentIcon.tooltip"
        :icon="currentIcon.icon"
        :color="currentIcon.color"
      />
    </template>
  </v-tooltip>
</template>

<script setup lang="ts">
import { MisFeatureStatus, type TMisFeatureStatus } from '~/api/misStatistics'

type Props = {
  status: TMisFeatureStatus
  tableScrollX: number
  statusToIconMap?: Record<TMisFeatureStatus, {
    icon: string
    color: string
    tooltip: string
  }>
}

const props = withDefaults(defineProps<Props>(), {
  statusToIconMap: () => ({
    [MisFeatureStatus.Available]: {
      icon: 'ui-icon-check-circle-filled',
      color: 'success',
      tooltip: 'Реализовано'
    },
    [MisFeatureStatus.NotAvailable]: {
      icon: 'ui-icon-octagon-x',
      color: 'ui-kit-text-error',
      tooltip: 'Недоступно в МИС'
    },
    [MisFeatureStatus.NotReleased]: {
      icon: 'ui-icon-circle-close',
      color: 'ui-kit-icon-secondary',
      tooltip: 'Не реализовано в МедФлекс'
    }
  })
})

const isActiveTooltip = ref(false)

watch(() => props.tableScrollX, () => {
  isActiveTooltip.value = false
})

const currentIcon = computed(() => props.statusToIconMap[props.status])
</script>
